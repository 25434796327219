<template>
  <div class="primary-market">
    <div class="container-xl">
      <paginated-list :class="!profile.primaryMarketAllow && 'show-rules-confirmed'"
                      :filter="filter"
                      :count="collection.count"
                      :total-count="collection.totalCount"
                      :data-loading="collection.loading"
                      @update="loadData"
      >
        <template v-slot:filter>
          <list-filter :filter="filter"/>
        </template>
        <template v-slot:loading>
          <div class="card">
            <div class="card-body">
              <i>Загрузка...</i>
            </div>
          </div>
        </template>
        <template v-slot:no-items>
          <rules-confirmed-modal :show="profile.primaryMarketAllow"/>
          <div class="card">
            <div class="card-body">
              <i>Нет доступных предложений</i>
            </div>
          </div>
        </template>
        <div class="card mb-3" v-for="item in collection.items">
          <div class="card-body">
            <div class="row">
              <div class="col-mortgage-rank">
                <div class="no-proxy-icon__container" v-if="item.noProxy">
                  <img class="no-proxy-icon rounded" src="@/assets/agreement-green.png" alt="Доверенность не нужна"
                       data-toggle="tooltip"
                       title="В этом проекте нотариальная доверенность для регистрации залога не требуется, т.к. ипотека в силу закона"/>
                </div>
                <div v-else>
                  <div class="text-success">{{ item.mortgageRank }}</div>
                </div>
              </div>
              <div class="col-name">
                <div class="h6">
                  <router-link class="project-link"
                               :to="{ name: 'primaryMarketProject', params: { uuid: item.uuid } }">
                    {{ item.name }}
                  </router-link>
                </div>
                <div class="small text-muted">ID {{ item.paymentCode }}</div>
                <div class="d-flex">
                  <div v-if="item.noProxy">
                  <span class="border-info border rounded p-1 text-info small" data-toggle="tooltip"
                        title="В этом проекте нотариальная доверенность для регистрации залога не требуется, т.к. ипотека в силу закона">
                    Без доверенности
                  </span>
                  </div>
                </div>
              </div>
              <div class="col-interest-rate">
                <!--                <div class="small text-muted" v-if="item.interestRateType === InterestRateType.VARIABLE">Прогноз</div>-->
                <div class="small text-muted">Ставка</div>
                <div v-if="item.interestRateType === InterestRateType.VARIABLE">
                <span class=" p-1 text-info">
                    {{ item.interestRate }}%
                  </span>
                  <CTooltip text="Прогноз доходности от продажи объекта"
                            :text-position=TooltipTextPosition.BOTTOM_RIGHT></CTooltip>
                </div>
                <div v-else>
                  <div>{{ item.interestRate }}%</div>
                </div>
              </div>
              <div class="col-initial-term">
                <div class="small text-muted">Срок</div>
                <div>{{ item.initialTerm }}&nbsp;мес.</div>
              </div>
              <div class="col-collateral-sum">
                <div class="small text-muted">Стоимость залога</div>
                <div>
                  <money :money="item.collateralSum"/>
                </div>
              </div>
              <div class="col-progress">
                <div class="row">
                  <div class="col-6">
                    <div class="small text-muted">Набрано</div>
                    <div>
                      <money :money="item.investedSum"/>
                    </div>
                  </div>
                  <div class="col-6 text-right">
                    <div class="small text-muted">Цель</div>
                    <div>
                      <money :money="item.targetSum"/>
                    </div>
                  </div>
                </div>
                <invest-progress-bar
                    :loan-sum="item.targetSum"
                    :invested-sum="item.investedSum"
                    :reservation-sum="calculateReservationSum(item)"
                />
              </div>
              <div class="col-actions">
                <router-link class="btn btn-sm btn-success"
                             :to="{ name: 'primaryMarketProject', params: { uuid: item.uuid } }"
                >
                  Инвестировать
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </paginated-list>
    </div>
  </div>
</template>

<script>
import {DataCollection, Money, PaginatedList, RulesConfirmedModal} from "@/components/common";
import {createProjectProvider} from "@/utils/di";
import ListFilter from "./ListFilter";
import CTooltip from "@/components/common/components/CTooltip.vue";
import {InterestRateType} from "@/utils/api-client/enums/InterestRateType";
import {TooltipTextPosition} from "@/utils/api-client/enums/TooltipTextPosition";
import InvestProgressBar from "@/components/common/components/InvestProgressBar.vue";
import {Money as ApiMoney} from "@/utils/api-client";

export default {
  name: "PrimaryMarket",
  computed: {
    InterestRateType() {
      return InterestRateType
    },
    TooltipTextPosition() {
      return TooltipTextPosition
    }
  },
  components: {
    InvestProgressBar,
    CTooltip,
    ListFilter,
    Money,
    PaginatedList,
    RulesConfirmedModal
  },
  inject: ["profile"],
  data() {
    return {
      displayModal: true,
      collection: new DataCollection(),
      filter: {}
    };
  },
  methods: {
    async loadData(page, perPage, {loanAmountFrom, loanAmountTo, ...filter}) {
      this.collection.startLoading();
      try {
        const provider = await createProjectProvider();
        const collection = await provider.getListFundRaising(
            {
              ...filter,
              loanAmountFrom: loanAmountFrom && loanAmountFrom * 100,
              loanAmountTo: loanAmountTo && loanAmountTo * 100
            },
            page,
            perPage
        );
        this.collection.setCollection(collection);
      } finally {
        this.collection.finishLoading();
      }
    },
    calculateReservationSum(project) {
      return new ApiMoney(project.reservations.reduce((res, el) => {
        return res + (el.lenderUuid !== this.profile.uuid ? el.reservationRemain.amount : 0)
      }, 0) + (project.collateralRegistrationSum ? project.collateralRegistrationSum.amount : 0),
          project.targetSum.currencyCode);
    },
  }
}
</script>

<style lang="scss">
.project-link {
  color: black;

  &:hover {
    color: black;
    text-decoration: none;
  }
}

.show-rules-confirmed {
  min-height: 300px;
  @media screen and (max-width: 600px) {
    min-height: 420px;
  }
}

.primary-market {
  .card .card-body {
    padding: 0.5rem 1.5rem;
  }

  .col-mortgage-rank,
  .col-name,
  .col-interest-rate,
  .col-initial-term,
  .col-collateral-sum,
  .col-progress,
  .col-actions {
    padding: 1%;
  }

  .col-mortgage-rank,
  .col-interest-rate,
  .col-initial-term,
  .col-collateral-sum,
  .col-actions {
    text-align: center;
  }

  .col-interest-rate,
  .col-initial-term,
  .col-collateral-sum,
  .col-progress {
    position: relative;
  }

  .col-interest-rate:before,
  .col-initial-term:before,
  .col-collateral-sum:before,
  .col-progress:before {
    content: "";
    background: #ecedef;
    position: absolute;
    bottom: 25%;
    left: 0;
    height: 50%;
    width: 0.125rem;
  }

  .col-interest-rate:before,
  .col-progress:before {
    background: none;
  }

  .col-mortgage-rank {
    width: 10%;
  }

  .col-name {
    width: 90%;
  }

  .no-proxy-icon {
    width: 32px;

    &__container {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -20%;
        width: 140%;
        height: 2px; /* Толщина линии */
        background-color: var(--red); /* Цвет линии */
        transform: rotate(-45deg);
        transform-origin: center;
      }
    }
  }


  .col-interest-rate {
    width: 33%;
  }

  .col-initial-term {
    width: 33%;
  }

  .col-collateral-sum {
    width: 34%;
  }

  .col-progress {
    width: 100%;
  }

  .col-actions {
    width: 100%;
  }

  .col-progress .progress {
    height: 0.25rem;
  }

  @media (min-width: 768px) { // Bootstrap MD
    .col-mortgage-rank {
      width: 7%;
    }

    .col-name {
      width: 40%;
    }

    .col-interest-rate {
      width: 14%;
    }

    .col-initial-term {
      width: 14%;
    }

    .col-collateral-sum {
      width: 25%;
    }

    .col-progress {
      width: 75%;
    }

    .col-actions {
      width: 25%;
      padding-top: 1.5rem;
    }
  }

  @media (min-width: 992px) { // Bootstrap LG
    .col-interest-rate:before,
    .col-progress:before {
      background: #ecedef;
    }

    .col-mortgage-rank {
      width: 5%;
    }

    .col-name {
      width: 27%;
    }

    .col-interest-rate {
      width: 9%;
    }

    .col-initial-term {
      width: 9%;
    }

    .col-collateral-sum {
      width: 13%;
    }

    .col-progress {
      width: 25%;
    }

    .col-actions {
      width: 12%;
      padding-top: 1.5rem;
    }
  }
}

</style>
